<template>
  <v-container
    fluid
    id="CnabRemessa"
    class="container-fundo pa-0 pb-1"
    :style="{ 'background-color': COR_PRINCIPAL }"
  >
    <v-toolbar-title
      v-bind:style="{ 'background-color': COR_PRINCIPAL }"
      class="headline lighten-2"
    >
      <!-- Título da página -->
      <v-toolbar-title class="text-white title-page mt-3 ml-4 "
        >Cnab Remessa
      </v-toolbar-title>
      <!-- Fim Título da página -->

      <!-- Botões de ação cabeçalho -->
      <v-toolbar-title
        class="mt-n2 mb-10 ml-4 pa-0 d-flex flex-column "
        dark
        elevation="0"
      >
        <div class="d-flex justify-space-between " tabindex="-1">

            <v-col cols="12" sm="2" md="2" lg="2" xl="2" class="px-2 py-0">
              <v-select
                v-model="dados.banco_selecionado"
                :items="opcoes_banco"
                item-text="banco_nome"
                item-value="cod_banco"
                label="Banco (Leiaute de arquivo)"
                dense
                class="inputs_header mr-0"
                flat
                dark
                solo-inverted
                return-object
              ></v-select>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="px-2 py-0">
              <v-select
                v-model="dados.cedente_selecionado"
                :items="opcoes_cedente"
                item-text="cedente_1"
                item-value="cod_pessoa_conta"
                label="Cedente"
                dense
                class="inputs_header mr-0"
                flat
                dark
                solo-inverted
                return-object
                :loading="loading_cedente"
              ></v-select>
            </v-col>

            <v-col cols="12" sm="2" md="2" lg="2" xl="2" class="px-2 py-0">
              <v-select
                label="N° arquivo"
                v-model="dados.arquivo_selecionado"
                :items="opcoes_arquivo"
                item-text="arquivo_situacao"
                item-value="cod_empreendcobrancacnab"
                dense
                class="inputs_header mr-0"
                flat
                dark
                solo-inverted
                return-object
                :loading="loading_arquivo"
              ></v-select>
            </v-col>

            <v-col cols="12" sm="2" md="2" lg="2" xl="2" class="px-2 py-0">
              <!-- Condição para exibir ícone de "Gerar" ou "Baixar" -->
              <div>
                <v-btn
                  class="text-white text-capitalize mr-2 mt-3"
                  :color="COR_PRINCIPAL"
                  :loading="loading_gerando"
                  elevation="0"
                  loading-text="Gerando"
                  :small="isMobile"
                  @click="cnab_remessa_240(dados_registros)">
                  <v-icon class="btn-icon mr-1" color="light-blue accent-2">
                    mdi-pencil-outline
                  </v-icon>
                  <span>Gerar</span>
                </v-btn>

                <v-btn
                v-if="dados_registros.length > 0"
                class="text-white text-capitalize mr-2 mt-3"
                :color="COR_PRINCIPAL"
                :loading="loading_baixando"
                elevation="0"
                loading-text="Baixando"
                :small="isMobile"
                @click="cnab_remessa_240_baixar(dados.arquivo_selecionado.cod_empreendcobrancacnab)">
                <v-icon class="btn-icon mr-1" color="light-blue accent-2">
                  mdi-file-download-outline
                </v-icon>
                Baixar
              </v-btn>
            </div>
          </v-col>

        </div>
      </v-toolbar-title>
      <!-- Fim Botões de ação cabeçalho -->
    </v-toolbar-title>

    <v-row class="mt-n11 mx-0">
      <!-- Grade ----------------------------------->
      <v-col cols="12" class="pa-0 mx-0 ">
        <v-container
          class="container-principal container-rounded rounded-lg tabela mx-0 px-0"
        >
          <v-card elevation="0" class="pb-0">
            <v-data-table
              :headers="headers"
              :items="dados_registros"
              :loading="loading"
              :items-per-page="-1"
              :height="tableHeight"
              fixed-header
              dense
              hide-default-header
              hide-default-footer
              loading-text="Carregando...  aguarde..."
              no-data-text="Nenhum Registro Encontrado"
              no-results-text="Nenhum Registro Encontrado"
            >
              <template v-slot:header>
                <thead>
                  <tr>
                    <th
                      v-for="h in headers"
                      :key="h.value"
                      :class="h.class"
                      class="pt-0 pb-3"
                    >
                      <span>{{ h.text }}</span>
                    </th>
                  </tr>
                </thead>
              </template>

              <template #item="{ item }">
                <tr>
                  <td>
                    <div class="status_ativo_box">
                      <span class="status_ativo">{{ item.emprcobrcnabrem_tipo_ocorr_desc }} </span>
                    </div>

                    <!--
                    <span v-if="item.tipo == 'ENTRADA'" class="status_ativo"
                      >{{ item.emprcobrcnabrem_tipo_ocorr_desc }}
                    </span>

                     <span
                      v-if="item.tipo == 'Pendente'"
                      class="status_pendente"
                      >{{ item.tipo }}
                    </span>

                    <span
                      v-if="item.tipo == 'Cancelado'"
                      class="status_cancelado"
                      >{{ item.tipo }}
                    </span>

                    <span
                      v-if="item.tipo == 'Rescindido'"
                      class="status_cancelado"
                      >{{ item.tipo }}
                    </span> -->
                  </td>

                  <td>
                    <span class="impressao">{{ formatDate(item.empreendcobr_impressao_dt )}}</span>
                  </td>

                  <td class="tipoNumero">
                    {{ item.empreendcobr_nossonumero  }} 
                  </td>

                  <td class="tipoNumero">
                    {{ item.cod_empreendcobranca }}
                  </td>

                  <td>
                    <div class="status_ativo_box">
                      <span class="status_ativo">{{item.parcinicial_parcfinal }} </span>
                    </div>
                  </td>

                  <td>
                  <span v-if="item.parcela_tipo != 'Antecipação'"
                      class="status_normal"
                      >{{ item.parcela_tipo }}
                  </span>
                  <span
                      v-if="item.parcela_tipo == 'Antecipação'"
                      class="status_antecipacao"
                      >{{ item.parcela_tipo }}
                    </span>
                  </td>

                  <td class="tipoNumero">
                    {{ formatDate(item.parcela_dt) }}
                  </td>

                  <td class="tipoNumero">
                    {{ formatPrice(item.empreendcobr_parc_valor) }}
                  </td>

                  <td class="tipoNumero">
                    {{ formatPrice(item.valor_desconto) }}
                  </td>

                  <td class="tipoNumero">
                    {{ formatPrice(item.valor_acrescimo + item.empreendcobr_impr_juros + item.empreendcobr_impr_multa) }}
                  </td>

                  <td class="tipoNumero">
                    {{ formatPrice(item.valor_pagar) }}
                  </td>

                  <td>
                    {{ item.unidade }}
                  </td>

                  <td>
                    <span class="cliente">{{ item.pessoa_nome }}</span>
                  </td>

                  <td>
                    {{ item.empreendvenda_contrato }}
                  </td>

                  <td>
                    <span class="empreendimento">{{
                      item.empreend_nome
                    }}</span>
                  </td>

                  <td class="tipoNumero">
                    {{ item.cod_empreendvenda }}
                  </td>
                </tr>
              </template>
            </v-data-table>
            <!-- FIM TABELA COBRANÇAS -------------------------------------------------------->

            <!-- RODAPE REGISTROS -------------------------------------------------------->
            <div class="mt-2 mb-1 ml-4">
              <table class="d-flex justify-end">
                <tbody class="" style="width: 70%">
                  <tr class="text sticky-table-footer-desktop">
                    <!-- <td
                      align="left"
                      style="width: 15%; background-color:#FFFFFF"
                    >
                      <span class="text-left body-2 font-weight-bold mr-1"
                        >Valor:</span
                      >
                      <span class="font-weight-medium body-2">R$ {{formatPrice(total_valor)}}</span>
                    </td> -->
                    <td
                      align="left"
                      style="width: 15%; background-color:#FFFFFF"
                    >
                      <span class="text-left body-2 font-weight-bold mr-1"
                        >Desconto:</span
                      >
                      <span class="font-weight-medium body-2">R$ {{formatPrice(desconto_total)}}</span>
                    </td>
                    <td
                      align="left"
                      style="width: 15%; background-color:#FFFFFF"
                    >
                      <span class="text-left body-2 font-weight-bold mr-1"
                        >Acr/Multa/Juros:</span
                      >
                      <span class="font-weight-medium body-2">R$ {{formatPrice(acrescimo_total)}}</span>
                    </td>
                    <td
                      align="left"
                      style="width: 15%; background-color:#FFFFFF"
                    >
                      <span class="text-left body-2 font-weight-bold mr-1"
                        >À pagar:</span
                      >
                      <span class="font-weight-medium body-2">R$ {{formatPrice(pagar_total)}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- FIM RODAPE REGISTROS -------------------------------------------------------->
          </v-card>
        </v-container>
      </v-col>
      <!-- Fim Grade ----------------------------------->
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import { API, API_BLOB } from "../../../services/API";
import { COR_PRINCIPAL, COR_SUBTITULO } from "../../../services/constantes";
import { formatDate, formatPrice } from "../../../services/funcoes";
//import { helperGenerateRemessaCNAB240 } from "../../../../node_modules/@banco-br/nodejs-cnab/dist/lib/helperGenerateRemessaCNAB240";

export default {
  name: "CnabRemessa",
  components: {},

  data() {
    return {
      loading: false,
      loading_arquivo: false,
      loading_cedente: false,
      loading_gerando: false,
      loading_baixando: false,
      currentPage: 1,

      COR_PRINCIPAL : COR_PRINCIPAL,
      COR_SUBTITULO : COR_SUBTITULO,
      formatDate    : formatDate,
      formatPrice   : formatPrice,

      // Objeto que foi selecionado em cada um dos SELECT
      dados: {
        banco_selecionado: null,
        cedente_selecionado: null,
        arquivo_selecionado: null,
      },

      total_valor     : 0,
      desconto_total  : 0,
      acrescimo_total : 0,
      pagar_total     : 0,


      /* Mostra no SELECT BANCO */
      opcoes_banco: [],

      /* Mostra no SELECT CEDENTE */
      opcoes_cedente: [],

      /* Mostra no SELECT ARQUIVO */
      opcoes_arquivo: [],

      /* TITULOS TABELA FINANCEIRO */
      headers: [
        { text: "Tipo", value: "tipo", class: "title-text" },
        { text: "Impressão", value: "impressao", class: "title-text" },
        { text: "Nosso Número", value: "nosso_numero", class: "title-text" },
        { text: "Código", value: "codigo", class: "title-text" },
        { text: "Parcela", value: "parcela", class: "title-text" },
        { text: "Tipo", value: "tipo_parcela", class: "title-text" },
        { text: "Dt. Vencimento", value: "dt_vencimento", class: "title-text" },
        { text: "Valor", value: "valor", class: "title-text" },
        { text: "Desconto", value: "desconto", class: "title-text" },
        { text: "Acr/Mul/Jur", value: "acr_mul_jur", class: "title-text" },
        { text: "A pagar", value: "a_pagar", class: "title-text" },
        { text: "Unidade", value: "unidade", class: "title-text" },
        { text: "Cliente", value: "cliente", class: "title-text" },
        { text: "N° contrato", value: "num_contrato", class: "title-text" },
        {
          text: "Empreendimento",
          value: "empreendimento",
          class: "title-text",
        },
        { text: "N° proposta", value: "num_proposta", class: "title-text" },
      ],

      /* TABELA REGISTROS */
      dados_registros: [],
    };
  },

  async mounted() {
    this.busca_bancos();
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.name === "xs";
    },

    tableHeight() {
      if (this.isMobile) return window.innerHeight - 149 + 30 - 90;
      else return window.innerHeight - 181 - 46;
    },

  },

  watch: {
    "dados.banco_selecionado"(val) {
      this.dados.cedente_selecionado  = null;
      this.opcoes_cedente             = [];
      this.dados.arquivo_selecionado  = null;
      this.opcoes_arquivo             = [];
      this.dados_registros            = [];
      this.loading_cedente            = true;     
      this.busca_cedente(val.cod_banco);     
      this.loading_cedente            = false;     

    },
    "dados.cedente_selecionado"(val) {
      if (val) {
//console.log("XXXXXXXX - dados.cedente_selecionado", val)
        this.dados.arquivo_selecionado  = null;
        this.opcoes_arquivo             = [];
        this.dados_registros            = []; 
        this.loading_arquivo            = true     
        this.busca_arquivo(val.cod_pessoa_conta, val.empreend_boleto_cedente_com_dig, val.cod_banco);
        this.loading_arquivo            = false
      }
    },
    "dados.arquivo_selecionado"(val){
      this.dados_registros = []
      this.exibe_arquivo(val)
    }
  },

  methods: {
    handlePageChange(value) {
      this.currentPage = value;
    },

    /**
     * Função para buscar bancos cadastrados
     */
    async busca_bancos() {
      const resposta = await API.get("banco", {});

      if (resposta) {
        if (resposta.data.result != "Não possui dados") {
          this.opcoes_banco = resposta.data.result;
//console.log('busca_bancos',resposta)
        } else this.opcoes_banco = [];
      }
    },

    /**
     * Função para buscar cedentes ao banco selecionado
     */
    async  busca_cedente(cod_banco) {
      const resposta = await API.get(`cnab_remessa_cedentes`, {params:{cod_banco: cod_banco}});
//console.log('busca_cedentes++++++++', resposta);
      if (resposta) {
        if (resposta.data.result != "Não possui dados") {
          const lo_Obj = resposta.data.result.map((e) => ({
                                                        cod_empreendcobrancacnab            : e.cod_empreendcobrancacnab,
                                                        cod_pessoa_conta                    : e.cod_pessoa_conta, 
                                                        cod_banco                           : e.cod_banco, 

                                                        cedente_nome                        : e.pessoa_nome,
                                                        numerocpfcgc                        : e.numerocpfcgc,

                                                        agencia_numero                      : e.agencia_numero,
                                                        agencia_numero_dig                  : e.agencia_numero_dig,
                                                        agencia_numero_com_dig              : e.agencia_numero_com_dig,

                                                        pessoa_conta_numero                 : e.pessoa_conta_numero,
                                                        pessoa_conta_numero_dig             : e.pessoa_conta_numero_dig,
                                                        pessoa_conta_numero_com_dig         : e.pessoa_conta_numero_com_dig,

                                                        empreend_boleto_cedente             : e.empreend_boleto_cedente,
                                                        empreend_boleto_cedente_dv          : e.empreend_boleto_cedente_dv,
                                                        empreend_boleto_cedente_com_dig     : e.empreend_boleto_cedente_com_dig,

                                                        empreend_boleto_convenio            : e.empreend_boleto_convenio,
                                                        empreend_boleto_convenio_dv         : e.empreend_boleto_convenio_dv,
                                                        empreend_boleto_convenio_com_dig    : e.empreend_boleto_convenio_com_dig,

                                                        cedente_1                : e.pessoa_nome + ' | Ag:' + 
                                                                                  e.agencia_numero + ' | Conta:' + 
                                                                                  e.pessoa_conta_numero + ' Cedente:' + 
                                                                                  e.empreend_boleto_cedente
                                            }));
          this.opcoes_cedente = lo_Obj;
//console.log('++++++++++',this.opcoes_cedente)
        }
        else
          this.opcoes_cedente = [];
      }
    },

    /**
     * Função para buscar arquivo referentes ao banco e cedente selecionado
     */
    async busca_arquivo(cod_pessoa_conta, empreend_boleto_cedente_com_dig, cod_banco) {
////console.log('busca_arquivo 1', cod_pessoa_conta, empreend_boleto_cedente, cod_banco);
      this.dados_registros    = [];      
      const resposta = await API.get(`cnab_remessa_arquivos`, {params:{ 
                                                                  cod_pessoa_conta        : cod_pessoa_conta, 
                                                                  empreend_boleto_cedente : empreend_boleto_cedente_com_dig, 
                                                                  cod_banco               : cod_banco,
                                                                  tipo                    : 'REM' 
                                                              }});
////console.log('busca_arquivo', resposta)                                                    
      if (resposta) {
        if (resposta.data.result != "Não possui dados") {
          this.opcoes_arquivo = resposta.data.result;
        } else this.opcoes_arquivo = [];
      }
    },

    /**
     * Função para exibir dados do arquivo na grade
     */
    async exibe_arquivo(val) {
//console.log('1 ===================', this.dados.arquivo_selecionado)      
      this.loading = true;
      this.dados_registros = [];
      const resposta = await API.get(`cnab_remessa_titulos`, {params:{
                                                                    cod_empreendcobrancacnab  : val.cod_empreendcobrancacnab, 
                                                                    empreend_boleto_cedente   : this.dados.cedente_selecionado.empreend_boleto_cedente_com_dig,
                                                                    cod_pessoa_conta          : this.dados.cedente_selecionado.cod_pessoa_conta, 
                                                                    cod_banco                 : this.dados.cedente_selecionado.cod_banco,
                                                              }});
////console.log('2 ===================', resposta)      
      this.loading = false;
      if (resposta) {
        if (resposta.data.result != "Não possui dados") {
          this.dados_registros = resposta.data.result;
          ////console.log("this.dados_registros", this.dados_registros)
          ////console.log("lenght", this.dados_registros.length)
          this.valor_total      = 0;
          this.desconto_total   = 0;
          this.acrescimo_total  = 0;
          this.pagar_total      = 0;
          var _this = this;
          this.dados_registros.map(function(tot) { 
            _this.total_valor     += tot.empreendvenda_parc_valor;
            _this.desconto_total  += tot.valor_desconto;
            _this.acrescimo_total += tot.valor_acrescimo + tot.empreendcobr_impr_juros + tot.empreendcobr_impr_multa;
            _this.pagar_total     += tot.valor_pagar;
          })

        } 
        else {
          this.dados_registros = [];
        }
      }
    },

    /**
     * Função para gerar remessa
     */
    async cnab_remessa_240(p_dados_registros) {
      this.loading_gerando = true;
////console.log("XXXXXXXXX - p_dados_registros", p_dados_registros);
//console.log("XXXXX - this.dados.cedente_selecionado", this.dados.cedente_selecionado)
      const banco_compensacao           = this.dados.banco_selecionado.banco_cod_compensacao.split('-')[0];
      const cedente_numero_inscricao    = this.dados.cedente_selecionado.numerocpfcgc;
      const cedente_agencia_codigo      = this.dados.cedente_selecionado.agencia_numero;
      const cedente_agencia_dv          = this.dados.cedente_selecionado.agencia_numero_dig;
      const cedente_conta_numero        = this.dados.cedente_selecionado.pessoa_conta_numero;
      const cedente_conta_numero_dig    = this.dados.cedente_selecionado.pessoa_conta_numero_dig;
      const cedente_codigo_numero       = this.dados.cedente_selecionado.empreend_boleto_cedente;
      const cedente_codigo_numero_dig   = this.dados.cedente_selecionado.empreend_boleto_cedente_dig;
      const empresa_nome                = this.dados.cedente_selecionado.cedente_nome;
      const arquivo_numero              = this.dados.arquivo_selecionado.numero_arquivo;
      const banco_nome                  = this.dados.banco_selecionado.banco_nome;

      let seq_lote = 0;
////console.log('banco_compensação',banco_compensacao.replace("-", ''),'split',this.dados.banco_selecionado.banco_cod_compensacao.split('-')[0])
      const lo_titulos = p_dados_registros.map(function(elem) { 
        let codigo_juros_mora, data_juros_mora, valor_juros_mora  = ''
        let codigo_desconto_1, data_desconto_1, valor_desconto_1  = ''
//console.log('nosso_numero', elem.empreendcobr_nossonumero,'-',elem.empreendcobr_nossonumero_dig)

        if (elem.empreendcobr_impr_juros_calc > 0){
          codigo_juros_mora = '1'
          data_juros_mora = elem.empreendcobr_parc_dt
          valor_juros_mora = elem.empreendcobr_impr_juros_calc
        } else {
          codigo_juros_mora = '0'
          data_juros_mora = ''
        }
        if (elem.empreendcobr_impr_desc_atevcto > 0){
          codigo_desconto_1 = '1';
          data_desconto_1   = elem.parcela_dt;
          valor_desconto_1  = elem.empreendcobr_impr_desc_atevcto;
        } else {
          codigo_desconto_1 = '0'
          data_desconto_1   = null;
          valor_desconto_1  = null;
        }
        var sacador_codigo_inscricao = 3;
        if (cedente_numero_inscricao.length == 11)
            sacador_codigo_inscricao = 1;
        if (cedente_numero_inscricao.length == 14)
            sacador_codigo_inscricao = 2;
////console.log('carteira',elem.empreend_boleto_carteira)
////console.log("elem.numerocpfcgc", elem.numerocpfcgc, elem.empreendcobr_nossonumero.replace("-", ''));
        return {
            // "Nosso Número:
            //    - Se emissão a cargo do Sicoob (vide planilha ""Contracapa"" deste arquivo):
            //          NumTitulo - 10 posições (1 a 10) = Preencher com zeros
            //          Parcela - 02 posições (11 a 12) - ""01"" se parcela única
            //          Modalidade - 02 posições (13 a 14) - vide planilha ""Contracapa"" deste arquivo
            //          Tipo Formulário - 01 posição  (15 a 15):
            //              ""1"" -auto-copiativo
            //              ""3""-auto-envelopável
            //              ""4""-A4 sem envelopamento
            //              ""6""-A4 sem envelopamento 3 vias
            //         Em branco - 05 posições (16 a 20)
            //    - Se emissão a cargo do Beneficiário (vide planilha ""Contracapa"" deste arquivo):
            //         NumTitulo - 10 posições (1 a 10): Vide planilha ""02.Especificações do Boleto"" deste arquivo item 3.13
            //         Parcela - 02 posições (11 a 12) - ""01"" se parcela única
            //         Modalidade - 02 posições (13 a 14) - vide planilha ""Contracapa"" deste arquivo
            //         Tipo Formulário - 01 posição  (15 a 15):
            //              ""1"" -auto-copiativo
            //              ""3""-auto-envelopável
            //              ""4""-A4 sem envelopamento
            //              ""6""-A4 sem envelopamento 3 vias
            //         Em branco - 05 posições (16 a 20)"
            nosso_numero              : elem.empreendcobr_nossonumero.replace("-", ""), 
            nosso_numero_dv           : elem.empreendcobr_nossonumero_dig,

            codigo_carteira           : elem.empreend_boleto_carteira,

            numero_documento          : elem.cod_empreendcobranca,  
            vencimento                : elem.parcela_dt,
            valor_titulo              : elem.valor_pagar,
            data_emissao              : elem.empreendcobr_impressao_dt, 
            codigo_juros_mora         : codigo_juros_mora,
            data_juros_mora           : data_juros_mora,
            valor_juros_mora          : valor_juros_mora,
            codigo_desconto_1         : codigo_desconto_1,
            data_desconto_1           : data_desconto_1,
            valor_desconto_1          : valor_desconto_1,
            uso_empresa               : elem.cod_empreendcobranca, 
            especie                   : '02',
            aceite                    : 'N',
            identificacao_emissao     : elem.boleto_emissao,
            identificacao_distribuicao: elem.boleto_distribuicao,

            // '1' = Protestar dias corridos
            // '3' = Não Protestar
            // '9' = Cancelar Instrução de Protesto
            //O código '9' deverá ser utilizado para cancelar um agendamento futuro de protesto e deverá estar atrelado obrigatóriamente ao código de entrada '31'.
            codigo_protesto           : '3',
            
            codigo_ocorrencia         : elem.emprcobrcnabrem_tipo_ocorr,

            //Q
            sacado_numero_inscricao   : elem.numerocpfcgc, 
            nome                      : elem.pessoa_nome.normalize('NFD').replace(/([\u0300-\u036f]|[^ 0-9a-zA-Z])/g, ''),
            logradouro                : elem.pessoa_end.normalize('NFD').replace(/([\u0300-\u036f]|[^ 0-9a-zA-Z])/g, ''),
            bairro                    : elem.pessoa_end_bairro.normalize('NFD').replace(/([\u0300-\u036f]|[^ 0-9a-zA-Z])/g, ''),
            cep                       : elem.pessoa_end_cep,
            cidade                    : elem.pessoa_cidade.normalize('NFD').replace(/([\u0300-\u036f]|[^ 0-9a-zA-Z])/g, ''),
            estado                    : elem.pessoa_cidade_uf,
            sacador_codigo_inscricao  : sacador_codigo_inscricao,
            sacador_numero_inscricao  : cedente_numero_inscricao,
            sacador_nome              : empresa_nome,
            //R
            valor_multa               : elem.empreendcobr_impr_multa_calc,

          }
      }  );
//console.log('map',lo_titulos)

      const dadosGeracao = {
        
        // Informações internas para o back-end localizar o arquivo e nomeá-lo
        cod_pessoa_conta              : this.dados.cedente_selecionado.cod_pessoa_conta,
        cod_banco                     : this.dados.banco_selecionado.cod_banco,

        // Header
        // Retirando o digito do banco
        codigo_banco                  : banco_compensacao, // Aqui está um problema
        banco_nome                    : banco_nome,
        cedente_numero_inscricao      : cedente_numero_inscricao,

        /* 
        * Bloco especial, SICOOB utiliza cedente conta como cedente código, demais bancos podem utilizar valores diferentes, desse modo está sendo passado duas vezes tratamento será feito no back
        */
        cedente_agencia_codigo        : cedente_agencia_codigo, 
        cedente_agencia_dv            : cedente_agencia_dv,
        cedente_conta                 : cedente_conta_numero,
        cedente_conta_dv              : cedente_conta_numero_dig,
        cedente_conta_debito          : cedente_conta_numero,
        cedente_conta_debito_dv       : cedente_conta_numero_dig,
        cedente_codigo                : cedente_codigo_numero,
        cedente_codigo_dv             : cedente_codigo_numero_dig,

        empresa_nome                  : empresa_nome.normalize('NFD').replace(/([\u0300-\u036f]|[^ 0-9a-zA-Z])/g, ''),
        cedente_numero_inscricao      : this.dados.cedente_selecionado.numerocpfcgc,

        conta_corrente_debito         : this.dados.cedente_selecionado.pessoa_conta_numero,
        conta_corrente_debito_dv      : this.dados.cedente_selecionado.pessoa_conta_numero_dig,

        nome_empresa                  : this.dados.cedente_selecionado.cedente_nome,
        data_geracao                  : moment().format(),
        hora_geracao                  : moment().format(),
        numero_sequencial_arquivo     : this.dados.arquivo_selecionado.numero_arquivo,

        // Titulos
        detalhe_segmento              : lo_titulos,
        
      };
////console.log('o que está vindo', dadosGeracao)

      //console.log('dadosGeracao-banco_compensação', banco_compensacao);
      const resposta = await API.post(`cnab_remessa_gerar`, {
                                                              dadosGeracao    : {...dadosGeracao}, 
                                                                                 banco           : banco_compensacao
                                                             }
                                      );
      if (resposta) {
        ////console.log('RESPOSTA -- ', resposta);
        var a = document.createElement("a"); //Create <a>
////console.log('conteudo', resposta)
        a.href = "data:file/txt;base64," + resposta.data.result.arquivo_conteudo; //Image Base64 Goes here
        a.download = resposta.data.result.arquivo_nome; //File name Here
        a.click(); //Downloaded file
      }

      //const finalresult = await helperGenerateRemessaCNAB240(dadosGeracao, this.dados.banco_selecionado.banco_cod_compensacao);
      ////console.log('finalresult---', finalresult);
      //return finalresult;
      this.loading_gerando = false;

    },

    /**
     * Função para buscar remessa já gerada
     */
    async cnab_remessa_240_baixar(cod_empreendcobrancacnab){
      this.loading_baixando = true;
      const resposta = await API.get(`cnab_remessa_baixar`, {params:{cod_empreendcobrancacnab}})
      if (resposta) {
        ////console.log('RESPOSTA -- ', resposta);
        var a = document.createElement("a"); //Create <a>
        a.href = "data:file/txt;base64," + resposta.data.result.arquivo_conteudo; //Image Base64 Goes here
        a.download = resposta.data.result.arquivo_nome; //File name Here
        a.click(); //Downloaded file
      }
      this.loading_baixando = false;
    },

  },

};
</script>

<style scoped>
.title-page {
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0px;
  margin-left: 16px !important;
}

.text-white {
  color: #ffffff;
  letter-spacing: 0px;
}

.text-capitalize {
  font-family: "Open Sans", sans-serif !important;
}

.btn-icon {
  font-size: 20px !important;
}

table tr {
  width: 100%;
}

.tipoNumero {
  text-align: end;
}

.status_antecipacao{
  color:#ff5252;
}
.status_normal{
  color:black;
}

.status_ativo {
  font-size: 14px !important;
  color: #fff;
  padding: 2px 12px;
}
.status_ativo_box {
  white-space: nowrap;
  background: #469c47;
  border-radius: 25px;
  height: 70%;
  width: 100%;
  margin: 5% 0 5% 0;
}

.status_pendente {
  font-size: 14px !important;
  background: goldenrod;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}

.status_cancelado {
  font-size: 14px !important;
  background: #ff5252;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}

.cliente,
.empreendimento,
.impressao {
  display: block;
  max-width: 25ch;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  z-index: 3;
}

/* PEGA OS ESTILOS DO BLOCO ACIMA E MUDA SÓ O MW */
.impressao {
  max-width: 20ch;
}

.sticky-table-footer-desktop td {
  font-weight: bold;
  position: sticky;
  background-color: #f8f8f8;
  color: rgba(0, 0, 0, 0.6);
}

.icon-help {
  margin-top: -2px;
  color: #fff;
  opacity: 0.5;
}

.btn {
  width: 100px;
}

.btn-icon {
  font-size: 20px;
}

.v-toolbar,
.v-sheet {
  display: flex !important;
  flex-direction: column !important;
  padding: 0px !important;
}

.inputs_header {
  margin: 0.8rem 0 1.2rem 0;
  height: 35px !important;
}

/* TABELA */
.container-principal {
  max-width: 100%;
  margin: auto !important;
}

.container-rounded {
  background: #fff !important;
}

.sticky-table-footer-desktop td {
  font-weight: bold;
  position: sticky;
  background-color: #f8f8f8;
  color: rgba(0, 0, 0, 0.6);
}

/* CELULAR */
@media (max-width: 599px) {
  .container-principal {
    max-width: 100% !important;
    margin: 0px !important;
    padding: 0px 0px 0px 0px !important;
  }

  .container-rounded {
    background: #fff !important;
    max-width: calc(100%) !important;
    margin: auto !important;
  }

  .theme--light.v-data-table {
    border-radius: 12px 12px 0px 0px !important;
  }
}
</style>
